import "./demographics.css";
import React, { useState } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import Sidebar from "./sidebar.js";
// import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Demographics = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: 'HackPrinceton Fall \'24'
      },
    },
  };

  // const charts = [
  //   {
  //     title: "Age Distribution",
  //     type: Doughnut,
  //     data: {
  //       labels: ['18-20', '21-23', '24-26', '27+'],
  //       datasets: [{
  //         data: [40, 35, 15, 10],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Class Year",
  //     type: Pie,
  //     data: {
  //       labels: ['Freshman', 'Sophomore', 'Junior', 'Senior', 'Graduate'],
  //       datasets: [{
  //         data: [25, 30, 20, 15, 10],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Major",
  //     type: Pie,
  //     data: {
  //       labels: ['Computer Science', 'Engineering', 'Business', 'Design', 'Other'],
  //       datasets: [{
  //         data: [40, 25, 15, 10, 10],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Gender Distribution",
  //     type: Doughnut,
  //     data: {
  //       labels: ['Male', 'Female', 'Non-binary', 'Other'],
  //       datasets: [{
  //         data: [50, 40, 7, 3],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Programming Experience",
  //     type: Bar,
  //     data: {
  //       labels: ['< 1 year', '1-2 years', '3-5 years', '5+ years'],
  //       datasets: [{
  //         label: 'Years of Experience',
  //         data: [20, 30, 35, 15],
  //         backgroundColor: '#FFCE56',
  //       }]
  //     },
  //     options: {
  //       ...options,
  //       indexAxis: 'y',
  //       scales: {
  //         x: {
  //           beginAtZero: true
  //         }
  //       }
  //     }
  //   },
  //   {
  //     title: "Hackathon Experience",
  //     type: Bar,
  //     data: {
  //       labels: ['First Time', '1-2 Before', '3-5 Before', '5+ Before'],
  //       datasets: [{
  //         label: 'Number of Hackathons',
  //         data: [40, 30, 20, 10],
  //         backgroundColor: '#36A2EB',
  //       }]
  //     }
  //   },
  //   {
  //     title: "Top Areas of Interest",
  //     type: Bar,
  //     data: {
  //       labels: ['AI/ML', 'Web Dev', 'Mobile Dev', 'IoT', 'Cybersecurity'],
  //       datasets: [{
  //         label: 'Interest Level',
  //         data: [35, 25, 20, 10, 10],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Familiar Technologies",
  //     type: Bar,
  //     data: {
  //       labels: ['JavaScript', 'Python', 'Java', 'C++', 'React', 'Node.js'],
  //       datasets: [{
  //         label: 'Familiarity Level',
  //         data: [80, 70, 60, 50, 65, 55],
  //         backgroundColor: '#4BC0C0',
  //       }]
  //     }
  //   },
  //   {
  //     title: "Type of Educational Institution",
  //     type: Doughnut,
  //     data: {
  //       labels: ['4-Year University', 'Community College', 'Bootcamp', 'Self-taught', 'Other'],
  //       datasets: [{
  //         data: [70, 10, 8, 7, 5],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Race Distribution",
  //     type: Pie,
  //     data: {
  //       labels: ['White', 'Asian', 'Black', 'Hispanic/Latinx', 'Other'],
  //       datasets: [{
  //         data: [35, 30, 15, 15, 5],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Hispanic/Latinx",
  //     type: Doughnut,
  //     data: {
  //       labels: ['Yes', 'No', 'Prefer not to say'],
  //       datasets: [{
  //         data: [15, 80, 5],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Underrepresented in Tech",
  //     type: Pie,
  //     data: {
  //       labels: ['Yes', 'No', 'Prefer not to say'],
  //       datasets: [{
  //         data: [30, 60, 10],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
  //       }]
  //     }
  //   },
  //   {
  //     title: "Sexual Orientation",
  //     type: Doughnut,
  //     data: {
  //       labels: ['Heterosexual', 'LGBTQ+', 'Prefer not to say'],
  //       datasets: [{
  //         data: [70, 25, 5],
  //         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
  //       }]
  //     }
  //   }
  // ];

  return (
    <div className="demographics-dashboard">
      <h1>HackPrinceton Demographics</h1>
      <div className="chart-grid">
        <Sidebar />
        {/* {charts.map((chart, index) => (
          <div key={index} className="chart-item">
            <h2>{chart.title}</h2>
            <chart.type data={chart.data} options={chart.options || options} />
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Demographics;
