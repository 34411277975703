import { useEffect, useState } from "react";
import Logo from "../../media/logo.png";
import "../register/register.css";
import axiosInstance from "../../api/axios.js";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(edu|ca|in)$/;

const ResetRequest = () => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/api/auth/reset_password", {
        email,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="register">
      <div className="row-columns">
        <div className="auth">
          <div className="page-meta">
            <h1 className="title">Reset Password</h1>
          </div>

          <div className="complete-log-form">
            <form className="auth-form" onSubmit={handleSubmit}>
              <div className="auth-form-inner">
                {message && <span className="error-message">{message}</span>}
                <input
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Student Email Address"
                  required
                ></input>
                {email && !validEmail && (
                  <span style={{ color: "red" }}>
                    Must be a valid email address
                  </span>
                )}
                <div className="auth-form__lower">
                  <button
                    type="submit"
                    className="button"
                    placeid="auth-button"
                    disabled={!validEmail}
                  >
                    <a className="reg-button">Reset</a>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <img
            className="logo"
            src={Logo}
            alt="HackPrinceton semester-specific theme graphic"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetRequest;
