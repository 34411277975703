import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../api/axios";
import "./whitelistemails.css";

const WhitelistTable = ({ whitelistPatterns, fetchList }) => {
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchList();
    }
  }, [authState.isAuthenticated]);

  //   const removePattern = async (email) => {
  //     try {
  //       console.log("Attempted");
  //       const response = await axiosInstance.delete(
  //         `/api/applications/whitelistemail/${email}`
  //       );

  //       console.log(response);
  //       fetchList();
  //     } catch (err) {
  //       console.error("Error removing email:", err);
  //     }
  //   };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Pattern</th>
            {/* <th style={{ width: "150px" }}></th> */}
          </tr>
        </thead>
        <tbody>
          {whitelistPatterns.map((row, index) => (
            <tr
              key={row.email}
              className={index % 2 === 0 ? "even-row" : "odd-row"}
            >
              <td>{row}</td>
              {/* <td>
                <button
                  className="delete-admin-button"
                  onClick={() => removePattern(row)}
                >
                  Remove Pattern
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WhitelistTable;
