import LoginForm from "./loginForm.js";
import Logo from "../../media/logo.png";
import "./s25-bg.css";
import { useState, useEffect } from "react";
import axiosInstance from "../../api/axios.js";
import "./movies.css";

const Login25 = () => {
  const [whitelistPatterns, setWhitelistPatterns] = useState([]);

  useEffect(() => {
    const fetchWhitelist = async () => {
      try {
        const response = await axiosInstance.get("/api/auth/whitelist");
        // console.log(response);
        setWhitelistPatterns(response.data);
        // console.log(response.data);
      } catch (err) {}
    };

    fetchWhitelist();
  }, []);

  return (
    <div className="register-s25">
      <div className="row-columns-s25">
        <div className="auth-s25">
          <div className="outer-divider">
            <div className="left-divider">
              <h1 className="title-s25">Welcome Back</h1>
              <img
                className="logo-s25"
                src={Logo}
                alt="HackPrinceton semester-specific theme graphic"
              />
            </div>
            <div className="right-divider">
              <LoginForm whitelistPatterns={whitelistPatterns} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login25;
