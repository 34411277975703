import CodeOfConduct from "../../media/mlh-code-of-conduct.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import Select from "react-select";
import Autocomplete from "./components/autocomplete";
import countries from "./components/countries";
import colleges from "./components/colleges";
import { useNavigate } from "react-router-dom";

const ApplicationForm = ({ email }) => {
  // Passing an email will enable View Mode
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({
    first: "",
    last: "",
    school: "",
    sections: [],
    checkboxes: [false, false, false],
    teamcode: "",
  });
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [resumeSubmitted, setResumeSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resumeMessage, setResumeMessage] = useState("");
  const [onTeam, setOnTeam] = useState("");
  const [team, setTeam] = useState("");
  const navigate = useNavigate();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FFFFFF" : "var(--background-color)",
      color: "var(--tertiary-color)",
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--background-color)",
      color: "var(--tertiary-color)",
      maxHeight: "25px",
      overflowY: "auto",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "var(--tertiary-color)",
      color: "var(--background-color)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "var(--background-color)",
    }),
  };

  useEffect(() => {
    const fetchQuestionsAndApplication = async () => {
      try {
        const applicationRequestUrl = email
          ? `/api/applications/${email}`
          : "/api/applications/application";

        const [questionsResponse, applicationResponse] = await Promise.all([
          axiosInstance.get("/api/applications/questions"),
          axiosInstance.get(applicationRequestUrl),
        ]);

        const questions = questionsResponse.data;
        const application = applicationResponse.data;

        const initialSections = questions.map((section) => ({
          section_name: section.section_name,
          answers: section.questions.map((question) => ({
            question_id: question.question_id,
            answer: "",
          })),
        }));

        if (application) {
          // console.log("Here #2");

          setFormData({
            first: application.first,
            last: application.last,
            school: application.school,
            sections: initialSections.map((section) => {
              const matchingSection = application.sections.find(
                (appSection) => appSection.section_name === section.section_name
              );
              if (matchingSection) {
                return {
                  section_name: section.section_name,
                  answers: section.answers.map((answer) => {
                    const matchingAnswer = matchingSection.answers.find(
                      (appAnswer) =>
                        appAnswer.question_id === answer.question_id
                    );
                    return matchingAnswer ? matchingAnswer : answer;
                  }),
                };
              }
              return section;
            }),
            checkboxes: application.checkboxes || [false, false, false],
            teamcode: application.teamcode || "",
          });

          if (
            application.hasOwnProperty("teamcode") &&
            application.teamcode !== null
          ) {
            setOnTeam("yes");
            setTeam(application.teamcode);
          }

          try {
            const resumeSubmittedUrl = email
              ? `/api/resume/submitted/${email}`
              : "/api/resume/submitted";
            const res = await axiosInstance.get(resumeSubmittedUrl);
            setResumeSubmitted(res.data.submitted);
            if (res.data.submitted) {
              setResumeMessage("Resume previously submitted");
            }
          } catch (error) {
            // console.log("Unable to check Resume status", error)
          }
        } else {
          // console.log("Here we are #3");

          setFormData({
            first: "",
            last: "",
            school: "",
            sections: initialSections,
            checkboxes: [false, false, false],
            teamcode: "",
          });
        }

        setSections(questions);
        setLoading(false);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchQuestionsAndApplication();
  }, []);

  const handleFileChange = (e) => {
    setResumeSubmitted(false);
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setFile(null);
      setResumeMessage("No resume uploaded.");
    } else if (selectedFile.size <= 2 * 1024 * 1024) {
      setFile(selectedFile);
      setResumeMessage("New resume successfully uploaded");
    } else {
      setFile(null);
      setResumeMessage(
        "Resume is too large. Please upload a file less than 2 MB."
      );
    }
  };

  const handleInputChange = (sectionIndex, questionIndex, value) => {
    const updatedSections = [...formData.sections];
    updatedSections[sectionIndex].answers[questionIndex].answer = value;
    setFormData({ ...formData, sections: updatedSections });
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...formData.checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setFormData({ ...formData, checkboxes: updatedCheckboxes });
  };

  const handleListChange = (sectionIndex, questionIndex, index, value) => {
    const updatedSections = [...formData.sections];
    let listAnswers =
      updatedSections[sectionIndex].answers[questionIndex].answer.split(",");
    listAnswers[index] = value;
    updatedSections[sectionIndex].answers[questionIndex].answer =
      listAnswers.join(",");
    setFormData({ ...formData, sections: updatedSections });
  };

  const addListEntry = (sectionIndex, questionIndex) => {
    const updatedSections = [...formData.sections];
    let listAnswers =
      updatedSections[sectionIndex].answers[questionIndex].answer.split(",");
    if (listAnswers.length < 3) {
      listAnswers.push("");
      updatedSections[sectionIndex].answers[questionIndex].answer =
        listAnswers.join(",");
      setFormData({ ...formData, sections: updatedSections });
    }
  };

  const removeListEntry = (sectionIndex, questionIndex, index) => {
    const updatedSections = [...formData.sections];
    let listAnswers =
      updatedSections[sectionIndex].answers[questionIndex].answer.split(",");
    listAnswers.splice(index, 1);
    updatedSections[sectionIndex].answers[questionIndex].answer =
      listAnswers.join(",");
    setFormData({ ...formData, sections: updatedSections });
  };

  const setSchool = (sectionIndex, questionIndex, value) => {
    setFormData({ ...formData, school: value });
  };

  const setCountry = (sectionIndex, questionIndex, value) => {
    handleInputChange(sectionIndex, questionIndex, value);
  };

  const handleMultiSelectChange = (
    sectionIndex,
    questionIndex,
    selectedOptions
  ) => {
    const updatedSections = [...formData.sections];
    const selectedValues = selectedOptions.map((option) => option.value);
    updatedSections[sectionIndex].answers[questionIndex].answer =
      selectedValues.join(",");
    setFormData({ ...formData, sections: updatedSections });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setErrorMessage("");

    if (!file && !resumeSubmitted) {
      setErrorMessage("Please select a valid resume file.");
      return;
    }

    try {
      // console.log(formData);

      const response = await axiosInstance.post(
        "/api/applications/application",
        formData
      );

      if (!resumeSubmitted) {
        const resumeData = new FormData();
        resumeData.append("resume", file);
        await axiosInstance.post("/api/resume/upload", resumeData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      // console.log(response);
      setMessage("Application submitted successfully");
      setErrorMessage("");
      navigate("/dashboard");
    } catch (error) {
      // console.log(error);
      setErrorMessage("Error submitting application");
    }
  };

  const handleSave = async () => {
    setMessage("");
    setErrorMessage("");

    // console.log(formData);

    try {
      const response = await axiosInstance.post(
        "/api/applications/save",
        formData
      );

      if (!resumeSubmitted && file) {
        const resumeData = new FormData();
        resumeData.append("resume", file);
        await axiosInstance.post("/api/resume/upload", resumeData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      setMessage("Application saved successfully");
      setErrorMessage("");
      navigate("/dashboard");
    } catch (error) {
      // console.log(error);
      setErrorMessage("Error saving application");
    }
  };

  const isSectionVisible = (conditionalOn) => {
    if (!conditionalOn) return true;
    for (const section of formData.sections) {
      for (const answer of section.answers) {
        if (answer.question_id === conditionalOn) {
          return answer.answer === "yes";
        }
      }
    }
    return false;
  };

  const handleOnTeam = async (e) => {
    setOnTeam(e.target.value);
    if (e.target.value === "yes") {
      const min = 100000; // Minimum 6-digit number
      const max = 99999999; // Maximum 8-digit number
      const id = Math.floor(Math.random() * (max - min + 1)) + min;

      setFormData({ ...formData, teamcode: id });
      setTeam(id);
    } else if (e.target.value === "no") {
      setFormData({ ...formData, teamcode: "" });
      setTeam("");
    }
  };

  const handleTeamChange = (e) => {
    setFormData({ ...formData, teamcode: e.target.value });
    setTeam(e.target.value);
  };

  function splitMultiselect(input) {
    // Split the input string by comma
    const parts = input.split(",");

    // Initialize an array to hold the final fragments
    const fragments = [];

    // Iterate through the parts to create fragments based on the rules
    parts.forEach((part, index) => {
      if (index === 0 || !part.startsWith(" ")) {
        // If it's the first element or doesn't start with a space, create a new fragment
        fragments.push(part);
      } else {
        // Otherwise, it's part of the previous fragment, so append it
        fragments[fragments.length - 1] += "," + part;
      }
    });

    // Trim spaces from each fragment
    return fragments.map((fragment) => fragment.trim());
  }

  return (
    <div className="apply-form-outer-div">
      <form onSubmit={handleSubmit} className="apply-form">
        <div className="app-form-div">
          {/* Code of Conduct */}
          <h1 className="section-title">Code of Conduct</h1>
          <div className="coc">
            {!email && (
              <p className="coc-p">
                We take inclusivity and safety seriously, and as part of that
                goal, we uphold the community guidelines shared by all MLH
                Member Events. To attend, you must agree to abide by the terms
                of the{" "}
                <a
                  className="coc-a"
                  href="https://github.com/MLH/mlh-policies/blob/main/code-of-conduct.md"
                  target="_blank"
                  rel="noreferrer"
                >
                  MLH Code of Conduct{" "}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />.
                </a>{" "}
                You must also agree to abide by the terms of the{" "}
                <a
                  className="coc-a"
                  href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MLH Contest Terms and Conditions{" "}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>{" "}
                and the{" "}
                <a
                  className="coc-a"
                  href="https://github.com/MLH/mlh-policies/blob/main/privacy-policy.md"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MLH Privacy Policy{" "}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />.
                </a>{" "}
                Please note that you may receive pre and post-event
                informational e-mails and occasional messages about hackathons
                from MLH as per the MLH Privacy Policy. Since HackPrinceton is a
                Princeton University sponsored event hosted on Princeton's
                campus, the rules detailed in{" "}
                <a
                  className="coc-a"
                  href="https://odus.princeton.edu/community-standards"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Princeton's Community Standards{" "}
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>{" "}
                take precedence for all things involving this hackathon.
              </p>
            )}
            <div className="checkbox-container">
              <input
                name="first-checkbox"
                type="checkbox"
                checked={formData.checkboxes[0]}
                onChange={() => handleCheckboxChange(0)}
                required
              />
              <label className="coc-l">
                I have read and agree to the MLH Code of Conduct.
                <label className="star"> *</label>
              </label>
            </div>
            <div className="checkbox-container">
              <input
                name="second-checkbox"
                type="checkbox"
                checked={formData.checkboxes[1]}
                onChange={() => handleCheckboxChange(1)}
                required
              />
              <label className="coc-l">
                I authorize you to share my application/registration information
                for event administration, ranking, MLH administration, pre- and
                post-event informational e-mails, and occasional messages about
                hackathons in-line with the MLH Privacy Policy. I further agree
                to the terms of both the MLH Contest Terms and Conditions and
                the MLH Privacy Policy.
                <label className="star"> *</label>
              </label>
            </div>
            <div className="checkbox-container">
              <input
                name="third-checkbox"
                type="checkbox"
                checked={formData.checkboxes[2]}
                onChange={() => handleCheckboxChange(2)}
              />
              <label className="coc-l">
                I authorize MLH to send me an email where I can further opt into
                the MLH Hacker, Events, or Organizer Newsletters and other
                communications from MLH.
              </label>
            </div>
          </div>
        </div>

        <div className="app-form-div">
          <h1 className="section-title">Basic Information</h1>
          <div className="app-form-question-div">
            <label className="label">
              First Name: <label className="star">*</label>
            </label>
            <input
              type="text"
              className="short-answer"
              value={formData.first}
              onChange={(e) =>
                setFormData({ ...formData, first: e.target.value })
              }
              required
            />
          </div>
          <div className="app-form-question-div">
            <label className="label">
              Last Name: <label className="star">*</label>
            </label>
            <input
              type="text"
              className="short-answer"
              value={formData.last}
              onChange={(e) =>
                setFormData({ ...formData, last: e.target.value })
              }
              required
            />
          </div>
          <div className="app-form-question-div">
            <label className="label">
              School: <label className="star">*</label>
            </label>
            <Autocomplete
              options={colleges}
              answer={formData.school}
              updateValue={setSchool}
            />
          </div>
        </div>
        {!loading &&
          sections.map(
            (section, sectionIndex) =>
              isSectionVisible(section.conditional_on) && (
                <div key={section.section_name} className="app-form-div">
                  <h1 className="section-title">{section.section_name}</h1>
                  {section.questions.map((question, questionIndex) => (
                    <div
                      key={question.question_id}
                      className="app-form-question-div"
                    >
                      {question.question_type === "numeric" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <input
                            type="number"
                            className="numeric"
                            min="16"
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            onChange={(e) =>
                              handleInputChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                            required
                          />
                        </>
                      )}
                      {question.question_type === "boolean" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <select
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            className="boolean"
                            onChange={(e) =>
                              handleInputChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">Please select one</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </>
                      )}
                      {question.question_type === "short_answer" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <textarea
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            className="short-answer"
                            onChange={(e) =>
                              handleInputChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                            required
                            maxLength={100}
                          />
                        </>
                      )}
                      {question.question_type === "long_answer" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <textarea
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            className="long-answer"
                            onChange={(e) =>
                              handleInputChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                            required
                            maxLength={1200}
                          />
                        </>
                      )}
                      {question.question_type === "multiple_choice" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <select
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            className="multiple-choice"
                            onChange={(e) =>
                              handleInputChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">Please select one</option>
                            {question.options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                      {question.question_type === "multiselect" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <Select
                            isMulti
                            options={question.options.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            value={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                                ? splitMultiselect(
                                    formData.sections[sectionIndex].answers[
                                      questionIndex
                                    ].answer
                                  ).map((value) => ({ value, label: value }))
                                : null
                            }
                            onChange={(selectedOptions) =>
                              handleMultiSelectChange(
                                sectionIndex,
                                questionIndex,
                                selectedOptions
                              )
                            }
                            className="multi-select"
                            styles={customStyles}
                            required
                          />
                        </>
                      )}
                      {question.question_type === "list" && (
                        <>
                          <label className="label">
                            {question.question_text}
                          </label>
                          {formData.sections[sectionIndex].answers[
                            questionIndex
                          ].answer
                            .split(",")
                            .map((item, index) => (
                              <div key={index}>
                                <input
                                  type="text"
                                  className="list"
                                  value={item}
                                  onChange={(e) =>
                                    handleListChange(
                                      sectionIndex,
                                      questionIndex,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                                {index > 0 && (
                                  <button
                                    type="button"
                                    className="list-button"
                                    onClick={() =>
                                      removeListEntry(
                                        sectionIndex,
                                        questionIndex,
                                        index
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            ))}
                          {formData.sections[sectionIndex].answers[
                            questionIndex
                          ].answer.split(",").length < 3 && (
                            <button
                              type="button"
                              className="list-button-add"
                              onClick={() =>
                                addListEntry(sectionIndex, questionIndex)
                              }
                            >
                              Add another
                            </button>
                          )}
                        </>
                      )}
                      {question.question_type === "resume" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <input
                            type="file"
                            className="resume-button"
                            onChange={handleFileChange}
                            accept=".pdf"
                          />
                          {resumeMessage && (resumeSubmitted || file) && (
                            <p className="resume-message">{resumeMessage}</p>
                          )}
                          {resumeMessage && !resumeSubmitted && !file && (
                            <p className="resume-error">{resumeMessage}</p>
                          )}
                        </>
                      )}
                      {question.question_type === "country" && (
                        <>
                          <label className="label">
                            {question.question_text}{" "}
                            <label className="star">*</label>
                          </label>
                          <Autocomplete
                            options={countries}
                            answer={
                              formData.sections[sectionIndex].answers[
                                questionIndex
                              ].answer
                            }
                            updateValue={setCountry}
                            sectionIndex={sectionIndex}
                            questionIndex={questionIndex}
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )
          )}

        <div className="app-form-div">
          <h1 className="section-title">Team Information</h1>
          <div className="app-form-question-div">
            <p className="team-code-desc">
              <i>
                If you do not have a team code yet, select yes in the box below
                and receive a new team code for yourself or your team.
              </i>
              <br></br>
              <br></br>
              <i>
                If someone on your team has already applied, select no and a box
                will appear to input their team code.
              </i>
              <br></br>
              <br></br>
              <i>
                If you are editing your application and already have a team
                code, yes will automatically be selected and your original team
                code will be displayed.
              </i>
              <br></br>
            </p>
            <label className="label">
              Would you like to generate a team code?
              <label className="star">*</label>
            </label>
            <select
              value={onTeam}
              onChange={handleOnTeam}
              className="multiple-choice"
              required
            >
              <option value="">Please select one</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {onTeam === "yes" && (
              <div className="af-teamcode-div">
                <label className="label">
                  Your team code is {team}. Please share this code with your
                  teammates.
                </label>
              </div>
            )}
            {onTeam === "no" && (
              <div className="af-teamcode-div">
                <label className="label">
                  What team code was provided to your first teammate who
                  applied?<label className="star">*</label>
                </label>
                <input
                  value={team}
                  type="number"
                  className="short-answer-tc"
                  onChange={handleTeamChange}
                  required
                />
              </div>
            )}
          </div>
        </div>

        {!email && (
          <div className="b-div">
            <button onClick={handleSave} type="button">
              Save & Continue Later
            </button>
            <button type="submit">Submit Application</button>
          </div>
        )}
        {message && <p className="appform-message">{message}</p>}
        {errorMessage && (
          <p className="appform-message-error">{errorMessage}</p>
        )}
      </form>
    </div>
  );
};

export default ApplicationForm;
