import { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../api/axios";
import "./reviewapps.css";

const ReviewApps = () => {
  const { authState } = useAuth();
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/applications/assigned-applications/${authState.user.email}`
        );

        // console.log(response.data.assigned);

        setApplications(response.data.assigned ? response.data.assigned : []);
        setLoading(false);
      } catch (err) {
        // console.log(err);
      }
    };

    fetchApplications();
  }, []);

  return (
    <div>
      <div className="dashboard">
        <h1 className="ad-dash-title">Applications to Review</h1>
        {!loading && applications.length === 0 && (
          <p className="ra-no-review">No Applicants to Review</p>
        )}

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="get-users">
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Email Address</th>
                    <th>Review Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {applications.map((user, index) => (
                    <tr
                      key={user.email}
                      className={index % 2 === 0 ? "even-row" : "odd-row"}
                    >
                      <td>{user.email}</td>
                      <td>{user.status}</td>
                      <td>
                        <a
                          className="users-table-view"
                          href={`/admin/reviewapps/${index}`}
                        >
                          View User
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewApps;
