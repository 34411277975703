import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import IntroAnimation from "./introAnimation";
import Login from "../login/login";
import MovieScreen from "../../components/S25-bg/moviescreen";

const AnimationWrapper = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(false);
      navigate("/login");
    }, 3200); // Adjust the duration of the animation as needed

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [navigate]);

  return showAnimation ? <IntroAnimation /> : <MovieScreen />;
};

export default AnimationWrapper;
