import { useState, useEffect, useContext } from "react";
import "./s25-bg.css";
import "./loginform25.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import AuthContext from "../../context/AuthContext";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(edu|ca|in)$/;

const LoginForm = ({ whitelistPatterns }) => {
  const navigate = useNavigate();

  const { setAuthState } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setValidEmail(
      EMAIL_REGEX.test(email) ||
        whitelistPatterns.some((pattern) => {
          try {
            // Convert pattern to RegExp if it's a string
            const regex =
              typeof pattern === "string" ? new RegExp(pattern) : pattern;

            // Test the email against the regex
            return regex.test(email);
          } catch (error) {
            console.error("Invalid regex pattern:", pattern);
            return false; // Return false if the regex is invalid
          }
        })
    );
  }, [email]);

  useEffect(() => {
    setErrorMessage("");
  }, [email, password]);

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post("/api/auth/login", {
        email,
        password,
      });
      setAuthState({ isAuthenticated: true, user: response.data.user });
      navigate("/dashboard");
    } catch (err) {
      // console.log(err);
      setErrorMessage("Invalid credentials.");
    }
  };

  return (
    <form className="lf-s25-outer">
      <h1 className="lf-s25-welcome-message">Welcome Back</h1>
      {errorMessage && <p className="lf-s25-error-message">{errorMessage}</p>}
      {email && !validEmail && (
        <p className="lf-s25-error-message">Must be a student email address</p>
      )}
      <label className="lf-s25-label">
        Email Address
        <input
          type="text"
          placeholder="Student Email Address"
          id="email"
          className="lf-s25-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        ></input>
      </label>
      <label className="lf-s25-label">
        Password
        <input
          type="password"
          placeholder="Password"
          id="password"
          className="lf-s25-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        ></input>
      </label>
      <p className="lf-s25-p">
        <a href="/register" className="lf-s25-a">
          Don't have an account?{" "}
        </a>
        <a href="/reset" className="lf-s25-a">
          Forgot Password?
        </a>
      </p>
      <button
        type="submit"
        placeid="auth-button"
        disabled={!validEmail || !password}
        className="lf-s25-button"
        onClick={submitForm}
      >
        Login
      </button>
    </form>

    // <form className="auth-form-s25">
    //   <p className="subtitle">
    //     Login to your HackPrinceton account to manage your application.
    //   </p>
    //   <div className="auth-form-inner">
    //     {errorMessage && <p className="error-message">{errorMessage}</p>}
    //     {email && !validEmail && (
    //       <p className="error-message">
    //         Must be a student email assigned by a university
    //       </p>
    //     )}
    //     <input
    //       type="text"
    //       placeholder="Student Email Address"
    //       id="email"
    //       value={email}
    //       onChange={(e) => setEmail(e.target.value)}
    //       required
    //     ></input>
    //     <input
    //       type="password"
    //       placeholder="Password"
    //       id="password"
    //       className="auth-form-password"
    //       value={password}
    //       onChange={(e) => setPassword(e.target.value)}
    //       required
    //     ></input>

    //     <p className="auth-meta">
    //       <a href="/register">Don't have an account? </a>
    //       <br></br>
    //       <a href="/reset">Forgot Password?</a>
    //     </p>
    //     <button
    //       type="submit"
    //       className="button"
    //       placeid="auth-button"
    //       disabled={!validEmail || !password}
    //       onClick={submitForm}
    //     >
    //       Login
    //     </button>
    //   </div>
    // </form>
  );
};

export default LoginForm;
