import React, { useState } from "react";
import "./createapp.css";

const InputField = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  placeholder,
}) => (
  <div className="createapp-form">
    <label>{label}:</label>
    <input
      className="ca-input"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
);

const OptionsForQuestionType = ({
  questionType,
  options,
  onOptionChange,
  addOption,
}) => {
  if (questionType === "multiple_choice" || questionType === "multiselect") {
    return (
      <div>
        <h3 className="ca-option">Options:</h3>
        {options.map((option, index) => (
          <div key={index}>
            <input
              type="text"
              value={option}
              onChange={(e) => onOptionChange(index, e.target.value)}
              placeholder="Option"
              className="ca-input"
            />
          </div>
        ))}
        <button className="add-option" onClick={addOption}>
          Add Option
        </button>
      </div>
    );
  }
  return null;
};

const CreateApp = () => {
  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Track if we are editing a question
  const [editIndex, setEditIndex] = useState(null); // Index of the question being edited
  const [errorMessage, setErrorMessage] = useState(null);

  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    questionType: "numeric",
    section: "",
    options: [""],
  });

  const [newSection, setNewSection] = useState({
    sectionText: "",
  });

  const handleFormChange = (e, target = "question") => {
    const { name, value } = e.target;
    if (target === "question") {
      setNewQuestion({ ...newQuestion, [name]: value });
    } else {
      setNewSection({ ...newSection, [name]: value });
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...newQuestion.options];
    newOptions[index] = value;
    setNewQuestion({ ...newQuestion, options: newOptions });
  };

  const addSection = () => {
    if (newSection.sectionText.trim()) {
      setSections([...sections, newSection]);
      setNewSection({ sectionText: "" });
    }
  };

  const addOption = () => {
    setNewQuestion({
      ...newQuestion,
      options: [...newQuestion.options, ""],
    });
  };

  const addQuestion = () => {
    if (!newQuestion.questionText || !newQuestion.section) {
      setErrorMessage(
        "Error: Question is missing information and cannot be submitted yet."
      );
      return;
    }

    setErrorMessage(null);

    if (isEditing) {
      // Update the existing question
      const updatedQuestions = [...questions];
      updatedQuestions[editIndex] = newQuestion;
      setQuestions(updatedQuestions);
      setIsEditing(false); // Exit edit mode
      setEditIndex(null); // Clear edit index
    } else {
      // Add a new question
      setQuestions([...questions, newQuestion]);
    }

    setNewQuestion({
      questionText: "",
      questionType: "numeric",
      options: [""],
      section: "",
    });
  };

  const editQuestion = (index) => {
    const questionToEdit = questions[index];
    setNewQuestion(questionToEdit);
    setIsEditing(true);
    setEditIndex(index);

    // TODO: REMOVE LATER
    // console.log(index);
    // console.log(sections);
    // console.log(questions);
  };

  const deleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const groupedQuestions = sections.map((section) => ({
    ...section,
    questions: questions.filter(
      (question) => question.section === section.sectionText
    ),
  }));

  return (
    <div className="createapp-container">
      <div className="createapp-form">
        {/* Section Form */}
        <h2 className="createapp-h2">Add New Section</h2>
        <InputField
          label="Section Name"
          name="sectionText"
          value={newSection.sectionText}
          onChange={(e) => handleFormChange(e, "section")}
        />
        <button className="ca-button" onClick={addSection}>
          Add Section
        </button>
      </div>

      {/* Question Form */}
      <div className="createapp-form">
        <h2 className="createapp-h2">
          {isEditing ? "Edit Question" : "Add New Question"}
        </h2>
        {errorMessage !== null && <p>{errorMessage}</p>}
        <InputField
          label="Question Text"
          name="questionText"
          value={newQuestion.questionText}
          onChange={(e) => handleFormChange(e, "question")}
        />
        <label>Question Type:</label>
        <select
          name="questionType"
          value={newQuestion.questionType}
          onChange={(e) => handleFormChange(e, "question")}
          className="ca-input"
        >
          <option value="numeric">Numeric</option>
          <option value="boolean">Boolean</option>
          <option value="short_answer">Short Answer</option>
          <option value="long_answer">Long Answer</option>
          <option value="multiple_choice">Multiple Choice</option>
          <option value="multiselect">Multiselect</option>
          <option value="list">List</option>
        </select>

        <OptionsForQuestionType
          questionType={newQuestion.questionType}
          options={newQuestion.options}
          onOptionChange={handleOptionChange}
          addOption={addOption}
        />
        <label className="input-label">
          Section:
          <select
            name="section"
            value={newQuestion.section}
            onChange={(e) => handleFormChange(e, "question")}
            className="ca-input"
          >
            <option value="">Select a Section</option>
            {sections.map((section, index) => (
              <option key={index} value={section.sectionText}>
                {section.sectionText}
              </option>
            ))}
          </select>
        </label>

        <button className="ca-button" onClick={addQuestion}>
          {isEditing ? "Update Question" : "Add Question"}
        </button>
      </div>

      {groupedQuestions.length > 0 && (
        <h2 className="createapp-h2">Questions List</h2>
      )}
      {groupedQuestions.map((section, index) => (
        <div key={index} className="section-container">
          <h3 className="section-title-ca">{section.sectionText}</h3>
          <ul className="list-ca">
            {section.questions.map((question, i) => (
              <li key={i} className="list-element">
                <strong>{question.questionText}</strong> -{" "}
                {question.questionType}
                {(question.questionType === "multiple_choice" ||
                  question.questionType === "multiselect") && (
                  <ul>
                    {question.options.map((option, j) => (
                      <li key={j}>{option}</li>
                    ))}
                  </ul>
                )}
                <button
                  className="ca-button-edit"
                  onClick={() => editQuestion(i)}
                >
                  Edit
                </button>
                <button
                  className="ca-button-edit"
                  onClick={() => deleteQuestion(i)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      ))}

      {groupedQuestions.length > 0 && (
        <div className="next-button-ca-div">
          <button className="ca-button">Confirm Questions</button>
        </div>
      )}
    </div>
  );
};

export default CreateApp;
