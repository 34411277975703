import RegistrationForm from "./registrationForm.js";
import Logo from "../../media/logo.png";
import "./register.css";
import axiosInstance from "../../api/axios.js";
import { useState, useEffect } from "react";

const Register = () => {
  const [whitelistPatterns, setWhitelistPatterns] = useState([]);

  useEffect(() => {
    const fetchWhitelist = async () => {
      try {
        const response = await axiosInstance.get("/api/auth/whitelist");
        // console.log(response);
        setWhitelistPatterns(response.data);
        // console.log(response.data);
      } catch (err) {}
    };

    fetchWhitelist();
  }, []);

  return (
    <div className="register">
      <div className="row-columns">
        <div className="auth">
          <div className="page-meta">
            <h1 className="title">Get Started</h1>
          </div>

          <RegistrationForm whitelistPatterns={whitelistPatterns} />

          <img
            className="logo"
            src={Logo}
            alt="HackPrinceton semester-specific theme graphic"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
