import Login from "../../pages/login/login";
import MovieScreen from "./moviescreen";
import "./s25-bg.css";

const S25 = () => {
  return (
    <div>
      <div className="rotated-iphone-bg">
        <Login />
      </div>
      <div className="normal-view-bg">
        <MovieScreen />
      </div>
    </div>
  );
};

export default S25;
