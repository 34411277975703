import "./25-css.css";
import Logo from "../../media/long-logo.png";
import SmallLogo from "../../media/logo.png";

const IntroAnimation = () => {
  return (
    <div className="record-player-bg-div">
      <div className="record-player">
        <input type="checkbox" id="headshell" defaultChecked />
        <label className="headshell" htmlFor="headshell"></label>
        <div className="plinth"></div>
        <div className="platter"></div>
        <div className="vinyl">
          <h1 className="retrohacks-txt">RetroHacks</h1>
        </div>
        <div className="top-circle"></div>
      </div>

      <img
        src={Logo}
        alt="Long HackPrinceton Logo"
        className="hp-title-intro-bg"
      />
      <img
        src={SmallLogo}
        alt="Long HackPrinceton Logo"
        className="hp-title-intro-bg-small"
      />
    </div>
  );
};

export default IntroAnimation;
