import "./confirm.css";

const ConfirmationDetails = ({
  travelReimbursement,
  housingAccomadations,
  status,
  housing_link,
  housing_date,
}) => {
  let housingDueDate = "2024-05-20";

  housingDueDate = new Date(housing_date + "T00:00:00Z");

  const formattedHousingDueDate = housingDueDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  if (travelReimbursement && housingAccomadations) {
    return (
      <div>
        <h3>Confirmation Details</h3>
        <p>
          We are able to provide you a sleeping arrangement in a shared
          Princeton dormitory. Please fill out the housing accommodation form
          below by {formattedHousingDueDate} at 11:59 PM. In addition, we are
          able to reimburse you up to $100 for your travels. You must have an
          active Zelle account to receive your reimbursement.
        </p>
        <a
          className="ha-form"
          href={housing_link}
          rel="noopener noreferrer"
          target="_blank"
        >
          Housing Accommodation Form
        </a>

        {(status === "accepted" || status === "waitlist_accepted") && (
          <p>
            Confirming your acceptance below acknowledges and accepts the above
            information.
          </p>
        )}
      </div>
    );
  } else if (travelReimbursement && !housingAccomadations) {
    return (
      <div>
        <h3>Confirmation Details</h3>
        <p>
          We are able to reimburse you up to $100 for your travels! You must
          have an active Zelle account to receive your reimbursement. If you
          also applied for housing, we are unfortunately unable to accommodate
          you.
        </p>
        {(status === "accepted" || status === "waitlist_accepted") && (
          <p>
            Confirming your acceptance below acknowledges and accepts the above
            information.
          </p>
        )}
      </div>
    );
  } else if (!travelReimbursement && housingAccomadations) {
    return (
      <div>
        <h3>Confirmation Details</h3>
        <p>
          We are able to provide you a sleeping arrangement in a shared
          Princeton dormitory! Please fill out the housing accommodation form
          below by {formattedHousingDueDate} at 11:59 PM. If you also applied
          for travel reimbursement, we are unfortunately unable to reimburse
          you.
        </p>
        <a
          className="ha-form"
          href={housing_link}
          rel="noopener noreferrer"
          target="_blank"
        >
          Housing Accommodation Form
        </a>
        {(status === "accepted" || status === "waitlist_accepted") && (
          <p>
            Confirming your acceptance below acknowledges and accepts the above
            information.
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <h3>Confirmation Details</h3>
        <p>
          If you applied for housing and/or travel reimbursements, we are
          unfortunately unable to provide you with either.
        </p>
        {(status === "accepted" || status === "waitlist_accepted") && (
          <p>
            Confirming your acceptance below acknowledges and accepts the above
            information.
          </p>
        )}
      </div>
    );
  }
};

export default ConfirmationDetails;
