import React, { useState } from "react";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./sidebar.css";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const MyComponent = () => {
  const [questions] = useState([
    {
      questionId: 1,
      questionText: "Do you like our service?",
      questionType: "multiselect",
    },
    {
      questionId: 2,
      questionText: "How satisfied are you?",
      questionType: "boolean",
    },
    {
      questionId: 3,
      questionText: "Would you recommend us?",
      questionType: "multiple_choice",
    },
    {
      questionId: 4,
      questionText: "Rate our service on a scale of 1 to 10.",
      questionType: "numeric",
    },
  ]);

  const responseCountsDictionary = {
    1: { Yes: 45, No: 30, Maybe: 15, "Not Sure": 10 },
    2: {
      "Very Satisfied": 50,
      Satisfied: 30,
      Neutral: 10,
      Unsatisfied: 5,
      "Very Unsatisfied": 5,
    },
    3: { Yes: 60, No: 20, Maybe: 15, "Not Sure": 5 },
    4: { 1: 5, 2: 10, 3: 15, 4: 20, 5: 25, 6: 10, 7: 5, 8: 5, 9: 2, 10: 3 }, // Numeric responses
  };

  function getResponseCounts(questionId) {
    return responseCountsDictionary[questionId] || {};
  }

  function transformToChartData(responseCounts) {
    const labels = Object.keys(responseCounts);
    const applied = Object.values(responseCounts);
    return { labels, applied };
  }

  const chartData = questions.reduce((acc, question) => {
    const responseCounts = getResponseCounts(question.questionId);
    const formattedData = transformToChartData(responseCounts);

    // Determine the chart type based on questionType
    let chartType;
    switch (question.questionType) {
      case "numeric":
        chartType = "bar"; // Bar chart for numeric responses
        break;
      case "multiple_choice":
      case "boolean":
        chartType = "pie"; // Pie chart for multiple choice and boolean questions
        break;
      case "multiselect":
        chartType = "bar"; // Bar chart for multiselect questions
        break;
      default:
        chartType = "pie"; // Default to pie chart
    }

    acc[question.questionText] = {
      title: question.questionText,
      type: chartType,
      labels: formattedData.labels,
      applied: formattedData.applied,
    };

    return acc;
  }, {});

  const Graph = ({ topic, dataType }) => {
    const { type, labels, applied, accepted } = chartData[topic] || {};
    const data = dataType === "applied" ? applied : accepted;

    const chartConfig = {
      labels,
      datasets: [
        {
          label:
            dataType === "applied"
              ? "Applied Applicants"
              : "Accepted Applicants",
          data,
          backgroundColor: [
            "#c70314",
            "#ff4d4d",
            "#ff9999",
            "#ff1a1a",
            "#e60000",
          ],
          borderColor: ["#b30000", "#cc4d4d", "#ff6666", "#ff1a1a", "#c70314"],
          borderWidth: 1,
        },
      ],
    };

    if (type === "pie") return <Pie data={chartConfig} />;
    if (type === "bar") return <Bar data={chartConfig} />;
    return <p>No data available.</p>;
  };

  const Sidebar = () => {
    const [selectedTopic, setSelectedTopic] = useState(
      "Do you like our service?"
    );
    const [dataType, setDataType] = useState("applied");

    const toggleDataType = () => {
      setDataType((prevType) =>
        prevType === "applied" ? "accepted" : "applied"
      );
    };

    return (
      <div className="sidebar-container">
        <div className="sidebar">
          <div className="toggle-container">
            <label className="toggle-label">
              <input
                type="radio"
                value="applied"
                checked={dataType === "applied"}
                onChange={toggleDataType}
                className="toggle-input"
              />
              <span className="toggle-text">Applied</span>
            </label>
            <label className="toggle-label">
              <input
                type="radio"
                value="accepted"
                checked={dataType === "accepted"}
                onChange={toggleDataType}
                className="toggle-input"
              />
              <span className="toggle-text">Accepted</span>
            </label>
            <label className="toggle-label">
              <input
                type="radio"
                value="confirmed"
                checked={dataType === "confirmed"}
                onChange={toggleDataType}
                className="toggle-input"
              />
              <span className="toggle-text">Confirmed</span>
            </label>
          </div>
          <ul>
            {Object.keys(chartData).map((topic) => (
              <li key={topic} onClick={() => setSelectedTopic(topic)}>
                {chartData[topic].title}
              </li>
            ))}
          </ul>
        </div>
        <div className="graph-container">
          <h2>{chartData[selectedTopic].title}</h2>
          <div className="graph-wrapper">
            <Graph topic={selectedTopic} dataType={dataType} />
          </div>
        </div>
      </div>
    );
  };

  const AllGraphs = () => {
    const [dataType, setDataType] = useState("applied");

    const toggleDataType = () => {
      setDataType((prevType) =>
        prevType === "applied" ? "accepted" : "applied"
      );
    };

    return (
      <div className="graph-container">
        <div className="toggle-container">
          <label className="toggle-label">
            <input
              type="checkbox"
              checked={dataType === "accepted"}
              onChange={toggleDataType}
              className="toggle-input"
            />
            <span className="toggle-switch"></span>
          </label>
          <span className="toggle-text">
            {dataType === "applied" ? "Applied" : "Accepted"}
          </span>
        </div>
        <h2>All Graphs</h2>
        <div className="graph-grid">
          {Object.keys(chartData).map((topic) => (
            <div className="graph-item" key={topic}>
              <h3>{chartData[topic].title}</h3>
              <Graph topic={topic} dataType={dataType} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Sidebar />
      {/* <AllGraphs /> */}
    </div>
  );
};

export default MyComponent;
