import "./apply.css";
import ApplicationForm from "./applicationForm.js";
import { useEffect, useState } from "react";
import axiosInstance from "../../api/axios.js";
import Loading from "../../components/loading/loading.js";
import LoadingPage from "../../components/loading/loadingPage.js";

const Apply = () => {
  const [hackathonStatus, setHackathonStatus] = useState("loading");

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/status"
        );
        setHackathonStatus(response.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchStatus();
  });

  return (
    <div className="apply">
      <div className="apply-title-container">
        <h1 className="app-title">Application</h1>
      </div>
      <div className="application">
        {hackathonStatus === "loading" ? (
          <LoadingPage />
        ) : hackathonStatus === "open" ? (
          <ApplicationForm />
        ) : (
          <div className="apply-form">
            <div className="auth-form-inner">
              <div className="app-form-div">
                <label className="label">
                  Applications are not currently open
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Apply;
