// Based heavily on batch acceptances, needs fixing

import { useState } from "react";
import axiosInstance from "../../../api/axios";
import "./automatedemails.css";
import "../batch/batch.css";

const AutomatedEmails = () => {
  const [acceptanceList, setAcceptanceList] = useState("");
  const [emails, setEmails] = useState([]);
  const [approvedEmails, setApprovedEmails] = useState([]);
  const [deniedEmails, setDeniedEmails] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [batchType, setBatchType] = useState("accepted");

  const handleAcceptances = (event) => {
    const input = event.target.value;
    setAcceptanceList(input);

    const EMAIL_REGEX = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
    const emailArray = input.match(EMAIL_REGEX) || [];
    setEmails(emailArray);
  };

  const handleBatch = (event) => {
    setBatchType(event.target.value);
  };

  const handleSubmitAcceptances = async (event) => {
    event.preventDefault();
    setApprovedEmails([]);
    setDeniedEmails([]);

    for (const email of emails) {
      try {
        const response = await axiosInstance.post(
          `/api/applications/status/${email}`,
          {
            status: batchType,
            // travel_reimbursement: travelReimbursement === "true",
            // housing_accommodations: housing === "true",
          }
        );

        setApprovedEmails((prevEmails) => [...prevEmails, email]);
      } catch (err) {
        console.error(err);
        setDeniedEmails((prevEmails) => [...prevEmails, email]);
      }
    }

    setFormSubmitted(true);
    setAcceptanceList("");
    setEmails([]);
  };

  return (
    <div>
      <div className="dashboard">
        <h1 className="header-title-batch">Automate Mass Emails</h1>
        <div className="searches-ad">
          <form onSubmit={handleSubmitAcceptances}>
            <h1 className="title-ad">Send Emails To:</h1>

            <select onChange={handleBatch} className="batch-select">
              <option value="started">Started Application</option>
              <option value="submitted">Submitted</option>
              <option value="accepted">Accepted</option>
              <option value="waitlisted">Waitlisted</option>
              <option value="waitlist_accepted">Waitlist Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="custom">Custom List</option>
            </select>

            {batchType === "custom" && (
              <div>
                <p>Submit a custom list of addresses to email:</p>
                <textarea
                  className="acceptances-input"
                  name="email"
                  placeholder="List emails here..."
                  value={acceptanceList}
                  onChange={handleAcceptances}
                />
              </div>
            )}
            <br />

            <div className="ae-options">
              <label className="ae-label">HTML Email File</label>
              <input
                type="file"
                className="ae-button"
                // onChange={handleFileChange}
                accept=".html"
              ></input>

              <label className="ae-label">Date and Time of Send</label>
              <input
                type="datetime-local"
                id="datetime"
                name="datetime"
                className="ae-date"
                // value={dateTime}
                // onChange={(e) => setDateTime(e.target.value)}
              />
            </div>

            <br />
            <button className="button-batch" type="submit">
              Send Email
            </button>
          </form>
        </div>

        {formSubmitted && (
          <div className="accepted-or-failed-div">
            <div className="accepted-div">
              <h1 className="acceptances-emails">
                {batchType.charAt(0).toUpperCase() + batchType.slice(1)}{" "}
                Approved
              </h1>
              <ul>
                {approvedEmails.map((email, index) => (
                  <li className="accept" key={index}>
                    {email}
                  </li>
                ))}
              </ul>
            </div>
            <div className="accepted-div">
              <h1 className="acceptances-emails">
                {batchType.charAt(0).toUpperCase() + batchType.slice(1)} Failed
              </h1>
              <ul>
                {deniedEmails.map((email, index) => (
                  <li className="accept" key={index}>
                    {email}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomatedEmails;
