import React, { useState } from "react";
import "./movies.css";
import DriveIn from "../../media/Spring-25-Registration-BG/driveintheater.png";
import Login25 from "./login";
import LoadingPage from "../loading/loadingPage";

const MovieScreen = () => {
  const [isLoading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false); // Switch off loading screen when image is loaded
  };

  return (
    <div className="ms-fade-in">
      {/* Loading Screen */}
      {isLoading && <LoadingPage />}

      {/* Main Content */}
      <div className={`content ${isLoading ? "hidden" : ""}`}>
        <div className="fullscreen-container">
          <div className="background-container">
            {/* Background Image */}
            <img
              src={DriveIn} // Path to your uploaded image
              alt="Drive-in Theater"
              className="background-image"
              onLoad={handleImageLoad}
            />

            {/* Login Form */}
            <div className="login-form-container">
              <div className="login-form">
                <Login25 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieScreen;
