import { useState, useEffect, useContext } from "react";
import "../register/register.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import AuthContext from "../../context/AuthContext";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(edu|ca|in)$/;

const LoginForm = ({ whitelistPatterns }) => {
  const navigate = useNavigate();

  const { setAuthState } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setValidEmail(
      EMAIL_REGEX.test(email) ||
        whitelistPatterns.some((pattern) => {
          try {
            // Convert pattern to RegExp if it's a string
            const regex =
              typeof pattern === "string" ? new RegExp(pattern) : pattern;

            // Test the email against the regex
            return regex.test(email);
          } catch (error) {
            console.error("Invalid regex pattern:", pattern);
            return false; // Return false if the regex is invalid
          }
        })
    );
  }, [email]);

  useEffect(() => {
    setErrorMessage("");
  }, [email, password]);

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post("/api/auth/login", {
        email,
        password,
      });
      setAuthState({ isAuthenticated: true, user: response.data.user });
      navigate("/dashboard");
    } catch (err) {
      // console.log(err);
      setErrorMessage("Invalid credentials.");
    }
  };

  return (
    <form className="auth-form">
      <p className="subtitle">
        Login to your HackPrinceton account to manage your application.
      </p>
      <div className="auth-form-inner">
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {email && !validEmail && (
          <p className="error-message">
            Must be a student email assigned by a university
          </p>
        )}
        <input
          type="text"
          placeholder="Student Email Address"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        ></input>
        <input
          type="password"
          placeholder="Password"
          id="password"
          className="auth-form-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        ></input>

        <p className="auth-meta">
          <a href="/register">Don't have an account? </a>
          <br></br>
          <a href="/reset">Forgot Password?</a>
        </p>

        {/* <div className="auth-form__lower"> */}
        <button
          type="submit"
          className="button"
          placeid="auth-button"
          disabled={!validEmail || !password}
          onClick={submitForm}
        >
          Login
        </button>
        {/* </div> */}
      </div>
    </form>
  );
};

export default LoginForm;
